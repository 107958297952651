<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>

    <div v-if="mounted">
      <BaseCustomerContact
        :contact="editedDealContact"
        :isNorwegianExhibitor="props.payload.isNorwegianExhibitor"
        :isSoldDeal="props.payload.isSoldDeal"
        :countryCodes="countryCodes"
      />
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" :disabled="!isAnyChanges" @click="onUpdate" data-test="confirm-btn">Save</button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive, ref, onMounted, watch } from "vue"
import { email, helpers, required } from "@vuelidate/validators"
import { Validations } from "@/utils/validation/validations"
import { useVuelidate } from "@vuelidate/core"
import { areEquivalent, deepClone } from "@/utils/helpers"
import { useMutation } from "@vue/apollo-composable"
import EDIT_DEAL_CONTACT from "./deal-contact-mutation.gql"
import EXHIBITOR_DEAL_CONTACTS from "@/constants/graphql/queries/deal-contacts-by-exhibitor_id.gql"
import BaseCustomerContact from "@/components/BaseCustomer/BaseCustomerContact"
import { validateMobileNumber, getCountryCodes } from "@/utils/phoneNumberUtils"

const store = inject("store")
const props = defineProps({
  payload: {
    type: Object,
    required: false,
    default: () => ({
      dealContact: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        title: ""
      },
      dealContactId: "",
      customerId: "",
      isSoldDeal: false,
      isNorwegianExhibitor: false
    })
  }
})

const emit = defineEmits(["loading"])

const countryCodes = ref([])
const mounted = ref(false)
const editedDealContact = reactive({
  firstname: props.payload.dealContact.firstname,
  lastname: props.payload.dealContact.lastname,
  phone: props.payload.dealContact.phone,
  email: props.payload.dealContact.email,
  title: props.payload.dealContact.title
})

onMounted(async () => {
  await validateContact(editedDealContact)
  mounted.value = true

  countryCodes.value = await getCountryCodes()
})

const getPhoneNumberWithoutSpaces = (contact) => {
  let number = contact.phone
  if (contact.validatedPhoneNumber) {
    number = (contact.validatedPhoneNumber.countryCode + contact.validatedPhoneNumber.number).replace(/\s/g, "")
  }
  return number
}

const getDefaultCountryCode = (phoneNumber, isNorwegian) => {
  if (!isNorwegian) return ""

  // Norwegian company and no phone number or phone number without country code
  if (!phoneNumber || !phoneNumber.startsWith("+")) {
    return "+47"
  }

  return ""
}

const validateContact = async (contact) => {
  if (contact.phone) {
    const ccode = getDefaultCountryCode(contact.phone, props.payload.isNorwegianExhibitor)
    const res = await validateMobileNumber(ccode + contact.phone)
    if (res) {
      contact.validatedPhoneNumber = res
      contact.phone = getPhoneNumberWithoutSpaces(contact)
    } else {
      contact.validatedPhoneNumber = {
        number: contact.phone,
        countryCode: ccode
      }
    }
  } else {
    // Handle user that has no phone number
    contact.validatedPhoneNumber = {
      number: "",
      countryCode: getDefaultCountryCode(contact.phone, props.payload.isNorwegianExhibitor)
    }
  }
  return contact
}

const rules = computed(() => {
  return {
    firstname: {
      required: helpers.withMessage(Validations.required, required)
    },
    lastname: {
      required: helpers.withMessage(Validations.required, required)
    },
    email: {
      required: helpers.withMessage(Validations.required, required),
      email: helpers.withMessage(Validations.email, email)
    }
  }
})

const v$ = useVuelidate(rules, editedDealContact)

const isAnyChanges = computed(() => {
  return !areEquivalent(props.payload.dealContact, {
    ...props.payload.dealContact,
    ...editedDealContact
  })
})

const { mutate: updateDealContact } = useMutation(EDIT_DEAL_CONTACT, () => ({
  update: (cache, { data: { updateDealContact } }) => {
    const { dealContactsByExhibitorId } = cache.readQuery({
      query: EXHIBITOR_DEAL_CONTACTS,
      variables: { exhibitorId: props.payload.customerId }
    })
    const itemIndex = dealContactsByExhibitorId.findIndex(
      (dealContact) => dealContact.id === props.payload.dealContactId
    )
    const updatedDealContactsByExhibitorId = deepClone(dealContactsByExhibitorId)
    updatedDealContactsByExhibitorId[itemIndex] = {
      ...updatedDealContactsByExhibitorId[itemIndex],
      ...updateDealContact
    }
    cache.writeQuery({
      query: EXHIBITOR_DEAL_CONTACTS,
      data: {
        dealContactsByExhibitorId: updatedDealContactsByExhibitorId
      },
      variables: { exhibitorId: props.payload.customerId }
    })
    closeModal()
  },
  throws: "never"
}))

const onUpdate = async () => {
  const isInputCorrect = await v$.value.$validate()
  if (!isInputCorrect) return

  const contact = deepClone(editedDealContact)
  delete contact.__typename
  delete contact.validatedPhoneNumber

  const inputVariables = {
    id: props.payload.dealContactId,
    input: { ...props.payload.dealContact, ...contact }
  }
  await updateDealContact(inputVariables)
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

watch(
  () => [mounted.value],
  () => {
    emit("loading", !mounted.value)
  },
  { immediate: true }
)
</script>
