<template>
  <div class="flex justify-between gap-1">
    <span v-if="getStageTitle" :class="getStageStyles" class="rounded-3xl px-4 py-2 w-fit">
      {{ getStageTitle }}
    </span>
  </div>
</template>

<script setup>
import { computed } from "vue"
import { DEAL_STAGES_KEYS } from "@/constants"

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      stage: null
    })
  }
})

const stages = {
  POTENTIAL: { bgColor: "bg-gray-100", textColor: "text-gray-600", title: DEAL_STAGES_KEYS.POTENTIAL },
  IN_FOCUS: { bgColor: "bg-yellow-100", textColor: "text-yellow-600", title: DEAL_STAGES_KEYS.IN_FOCUS },
  CONTACT_MADE: { bgColor: "bg-blue-100", textColor: "text-blue-600", title: DEAL_STAGES_KEYS.CONTACT_MADE },
  PLACEMENT: { bgColor: "bg-green-100", textColor: "text-green-600", title: DEAL_STAGES_KEYS.PLACEMENT }
}
const getStageStyles = computed(() =>
  props.payload.stage ? `${stages[props.payload.stage].bgColor} ${stages[props.payload.stage].textColor}` : ""
)
const getStageTitle = computed(() => (props.payload.stage ? stages[props.payload.stage].title : null))
</script>
