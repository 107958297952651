<template>
  <div class="flex flex-col relative w-full" v-click-outside="closeDropdownMenu">
    <div
      class="w-full input flex justify-between items-center p-1.5"
      :class="[
        { 'ring-blue-500 border-blue-500': isOpen },
        { 'min-h-[2.5rem]': !props.selectedItems?.length },
        { 'bg-gray-50': props.disabled }
      ]"
      type="button"
      @click="toggleDropdownMenu()"
    >
      <div class="flex flex-wrap gap-1 w-fit">
        <label v-if="!selectedItems?.length" class="ml-1.5 text-gray-500">{{ props.placeholder }}</label>

        <div
          v-for="item in selectedItems"
          :key="item.id"
          class="flex items-center bg-gray-100 py-1 px-2 rounded"
          data-test="selected-items"
        >
          <span class="break-all">{{ item.title }}</span>
          <button
            v-if="!props.disabled"
            type="button"
            class="inline-flex items-center p-0.5 ml-2 text-sm bg-transparent rounded-sm"
            @click.stop="removeItem(item.value)"
          >
            <svg-icon name="close" classNames="text-gray-900 w-3 h-3" />
          </button>
        </div>
      </div>

      <svg-icon name="arrow-down" class="ml-3"></svg-icon>
    </div>
    <!-- Dropdown menu -->
    <div
      v-if="isOpen"
      :class="topPosition || showTopPosition ? 'bottom-full' : 'top-full'"
      class="absolute z-10 w-full bg-white rounded divide-y divide-gray-100 shadow max-h-64 overflow-auto"
      ref="dropdownMenu"
    >
      <div class="py-1 text-sm text-gray-700">
        <div
          v-for="({ title, value }, i) in props.options"
          class="flex items-center py-2 px-4 hover:bg-indigo-50"
          :key="i"
        >
          <input
            :id="'element-' + i"
            class="checkbox mr-3 h-5 w-5"
            type="checkbox"
            :checked="isChecked(value)"
            @change="onOptionSelect({ title, value }, $event)"
          />
          <label :for="'element-' + i" class="text-normal cursor-pointer w-fit">{{ title }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, ref, watch } from "vue"
import { areArraysEquivalent, deepClone } from "@/utils/helpers"

/**
 * @property {Array.<{value: string, title: string}>} props.options - array of objects (all available options) with title and value
 * @property {Array<String>} props.selectedItems - list of selected options values
 **/
const props = defineProps({
  options: { type: Array, required: true },
  selectedItems: { type: Array, required: true, nullable: true },
  topPosition: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  changeOnBlur: {
    type: Boolean,
    required: false,
    default: false
  },
  placeholder: {
    type: String,
    required: false,
    default: ""
  }
})

const emit = defineEmits(["change"])

const dropdownMenu = ref()
const isOpen = ref(false)
const selectedItems = ref(deepClone(props.selectedItems).sort((a, b) => a.title.localeCompare(b.title)))
const showTopPosition = ref(false)

const toggleDropdownMenu = () => {
  if (props.disabled) return

  isOpen.value = !isOpen.value

  nextTick(() => {
    checkMenuPosition()
  })
}

const closeDropdownMenu = () => {
  if (!isOpen.value) return
  isOpen.value = false

  if (props.changeOnBlur && !areArraysEquivalent(selectedItems.value, props.selectedItems)) {
    emit("change", selectedItems.value)
  }
}

const onOptionSelect = (option, value) => {
  if (value.target.checked) {
    selectedItems.value.push(option)
  } else {
    removeItem(option, false)
  }

  if (!props.changeOnBlur) {
    emit("change", selectedItems.value)
  }
}

const isChecked = (optionValue) => {
  return selectedItems.value.some((el) => el.value === optionValue)
}

const removeItem = (optionValue, emitChanges = true) => {
  selectedItems.value.splice(
    selectedItems.value.findIndex((el) => el.value.toString() === optionValue.toString()),
    1
  )

  if (emitChanges) {
    emit("change", selectedItems.value)
  }
}

const checkMenuPosition = () => {
  // check the position of the dropdownMenu element, if this element doesn't fit at the bottom, then it will be shown at the top
  showTopPosition.value =
    window.innerHeight - dropdownMenu.value?.offsetHeight <= dropdownMenu.value?.getBoundingClientRect().top
}

watch(
  () => props.selectedItems,
  (newValue) => {
    selectedItems.value = deepClone(newValue)
  }
)
</script>
