<template>
  <div class="flex flex-col max-h-inherit space-y-5 text-start p-4 w-full">
    <slot name="title"></slot>
    <BaseContactPersons
      ref="contactPersonsRef"
      :payload="props.payload"
      @loading="(isLoading) => emit('loading', isLoading)"
    />
    <div class="flex justify-center space-x-2">
      <button class="button button-bg-light" @click="closeModal">Cancel</button>
      <button class="button" :disabled="isSaveButtonDisabled" @click="onSaveHandler" data-test="save">Save</button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref } from "vue"
import { deepClone } from "@/utils/helpers"
import { useMutation } from "@vue/apollo-composable"
import DEAL_CONTACTS_MUTATION from "@/constants/graphql/mutations/deal-contacts-mutation.gql"
import DEAL_CONTACTS from "@/constants/graphql/queries/deal-contacts.gql"
import BaseContactPersons from "@/components/BaseCustomer/BaseDealContactPersons"

const store = inject("store")
const emit = defineEmits(["loading"])
const props = defineProps({
  payload: {
    type: Object,
    required: true,
    default: () => ({ contacts: [], previousContacts: [], isSoldDeal: false, isNorwegianExhibitor: false })
  }
})

const contactPersonsRef = ref()

const isSaveButtonDisabled = computed(() => {
  return !contactPersonsRef?.value?.areAnyChanges
})

const { mutate: updateContactPersons } = useMutation(DEAL_CONTACTS_MUTATION, () => ({
  variables: {
    dealId: props.payload.dealId
  },
  update: (cache, { data: { syncDealContacts: updatedContacts } }) => {
    if (updatedContacts && Array.isArray(updatedContacts)) {
      // Need to update cache as user may have added or removed contacts
      cache.writeQuery({
        query: DEAL_CONTACTS,
        data: {
          dealContactsByDealId: deepClone(updatedContacts).sort((a) => (a.role === "PRIMARY" ? -1 : 1))
        },
        variables: {
          dealId: props.payload.dealId
        }
      })
      closeModal()
    }
  },
  throws: "never"
}))

const onSaveHandler = async () => {
  const { isValid, contacts } = contactPersonsRef.value
  if (!(await isValid)) return

  const input = deepClone(contacts).map((contact) => {
    delete contact.__typename
    delete contact.validatedPhoneNumber
    return {
      ...contact,
      is_mystand_admin: !!contact.is_mystand_admin
    }
  })
  await updateContactPersons({ input })
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
