<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>
    <div class="flex-col">
      <label class="label"> Role </label>
      <select
        class="input mt-1"
        :class="{ 'input--invalid': v$.role?.$error }"
        v-model="editedMember.role"
        @blur="v$.role.$touch"
        data-test="role-select"
      >
        <option v-for="(title, key) in teamUserRoles" :key="key" :value="key">
          {{ title }}
        </option>
      </select>
      <div v-if="v$.role.$error" class="text-sm text-red-700">{{ v$.role.$errors[0].$message }}</div>
    </div>
    <div class="flex-col">
      <label class="label flex items-center space-x-2">
        <input
          type="checkbox"
          :checked="!editedMember.email_sendouts_disabled"
          @change="handleChange($event)"
          class="checkbox h-5 w-5"
          data-test="email-sendouts-checkbox"
          :disabled="!props.payload.canLogin"
        />
        <span>Signup emails</span>
      </label>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" @click="onUpdateMember" data-test="confirm-btn">Save</button>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive, computed, watch } from "vue"
import { useMutation, useQuery } from "@vue/apollo-composable"
import EDIT_MEMBER_MUTATION from "@/constants/graphql/mutations/member-editing-mutation.gql"
import TEAM_USER_ROLES_QUERY from "@/constants/graphql/queries/team-user-roles-to-grant.gql"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"
import { useVuelidate } from "@vuelidate/core"
import { required, helpers } from "@vuelidate/validators"
import { Validations } from "@/utils/validation/validations"

const store = inject("store")
const emit = defineEmits(["loading"])
const props = defineProps({ payload: Object })

/* AVAILABLE USER ROLES TO GRANT */
const { result: teamUserRolesResults, loading: loadingRoles } = useQuery(
  TEAM_USER_ROLES_QUERY,
  {
    userId: store.user.state.user.id,
    teamId: props.payload.teamId
  },
  () => ({
    fetchPolicy: "cache-first"
  })
)
const teamUserRoles = computed(() => teamUserRolesResults.value?.getTeamUserRolesForTeamByTeamUser ?? {})

/* TEAM MEMBER EDITING */
const editedMember = reactive({
  team_id: props.payload.teamId,
  user_id: props.payload.userId,
  role: props.payload.role,
  email_sendouts_disabled: props.payload.canLogin ? props.payload.email_sendouts_disabled : true
})

const rules = computed(() => {
  return {
    role: {
      required: helpers.withMessage(Validations.required, required)
    },
    email_sendouts_disabled: {
      required: helpers.withMessage("This field is required", required),
      boolean: helpers.withMessage("Must be a boolean value", (value) => typeof value === "boolean")
    }
  }
})

const v$ = useVuelidate(rules, editedMember)

const onUpdateMember = async () => {
  const isInputCorrect = await v$.value.$validate()
  if (!isInputCorrect) return

  await updateMember()
}

const { mutate: updateMember } = useMutation(EDIT_MEMBER_MUTATION, () => ({
  variables: { input: editedMember },
  update: ({ data: dataResult }) => {
    if (dataResult) {
      closeModal()
      store.ui.methods.setSnackbarMessage({
        message: "Successfully updated team member",
        type: SNACKBAR_MESSAGE_TYPES.SUCCESS
      })
    }
  },
  throws: "never"
}))

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

const handleChange = (event) => {
  const checked = event.target.checked
  editedMember.email_sendouts_disabled = !checked
}

watch(
  () => loadingRoles.value,
  () => {
    emit("loading", loadingRoles.value)
  },
  { immediate: true }
)
</script>
