const Sidebar = () => import(/* webpackChunkName: "Sidebar" */ "@/components/TheSidebar")
const Header = () => import(/* webpackChunkName: "Header" */ "@/components/TheHeader")
const Customers = () => import(/* webpackChunkName: "Customers" */ "@/containers/Customers")
const CustomersTable = () => import(/* webpackChunkName: "Customers" */ "@/containers/Customers/CustomersList")
const CustomerGeneral = () => import(/* webpackChunkName: "Customers" */ "@/containers/Customers/Customer/General")
const CustomerDealsTable = () =>
  import(/* webpackChunkName: "Customers" */ "@/containers/Customers/Customer/Deals/DealsTable")
const CustomerActivitiesTable = () =>
  import(/* webpackChunkName: "Customers" */ "@/containers/Customers/Customer/Activities/ActivitiesTable")
const CustomerHeaderTitle = () =>
  import(/* webpackChunkName: "CustomerHeaderTitle" */ "@/containers/Customers/Customer/CustomerHeaderTitle")
const DealGeneral = () => import(/* webpackChunkName: "DealGeneral" */ "@/containers/Deal/General")
const DealActivitiesTable = () =>
  import(/* webpackChunkName: "DealActivitiesTable" */ "@/containers/Deal/Activities/ActivitiesTable")
const DealStand = () => import(/* webpackChunkName: "DealStand" */ "@/containers/Deal/Stand")
const Activity = () => import(/* webpackChunkName: "Activity" */ "@/containers/Activity")
const ActivityHeaderTitle = () =>
  import(/* webpackChunkName: "ActivityHeaderTitle" */ "@/containers/Activity/ActivityHeaderTitle")
const DealExtraOrders = () => import(/* webpackChunkName: "DealServices" */ "@/containers/Deal/ExtraOrders")
const DealHistory = () => import(/* webpackChunkName: "DealHistory" */ "@/containers/Deal/History")

export default {
  path: "/customers",
  name: "customers",
  components: {
    default: Customers,
    header: Header,
    sidebar: Sidebar
  },
  meta: {
    breadcrumb: "customers"
  },
  redirect: { name: "customers-list" },
  children: [
    {
      path: "",
      name: "customers-list",
      meta: {
        header: "customers"
      },
      component: CustomersTable
    },
    {
      path: ":id",
      name: "customer",
      meta: {
        navigationTabsKey: "customer"
      },
      redirect: { name: "customer-general" },
      children: [
        {
          path: "general",
          name: "customer-general",
          props: true,
          components: { default: CustomerGeneral, dynamicHeaderTitle: CustomerHeaderTitle },
          meta: {
            breadcrumb: "general"
          }
        },
        {
          path: "deals",
          name: "customer-deals",
          props: true,
          meta: {
            breadcrumb: "customer deals"
          },
          redirect: { name: "customer-deals-table" },
          children: [
            {
              path: "",
              name: "customer-deals-table",
              props: true,
              components: { default: CustomerDealsTable, dynamicHeaderTitle: CustomerHeaderTitle }
            },
            {
              path: ":dealId",
              name: "customer-deal",
              props: { default: true },
              meta: {
                dynamicHeader: "companyName",
                navigationTabsKey: "customer-deal",
                requiredHeaderDynamicComponents: ["CurrentProject"]
              },
              redirect: { name: "customer-deal-general" },
              children: [
                {
                  path: "general",
                  name: "customer-deal-general",
                  props: true,
                  components: { default: DealGeneral, dynamicHeaderTitle: CustomerHeaderTitle },
                  meta: {
                    breadcrumb: "deal general"
                  }
                },
                {
                  path: "activities",
                  name: "customer-deal-activities",
                  meta: {
                    breadcrumb: "deal activities"
                  },
                  redirect: { name: "customer-deal-activities-table" },
                  children: [
                    {
                      path: "",
                      name: "customer-deal-activities-table",
                      props: true,
                      components: { default: DealActivitiesTable, dynamicHeaderTitle: CustomerHeaderTitle }
                    },
                    {
                      path: ":activityId",
                      name: "customer-deal-activity",
                      props: true,
                      components: { default: Activity, dynamicHeaderTitle: CustomerHeaderTitle },
                      meta: {
                        navigationTabsKey: null,
                        breadcrumb: "activity",
                        header: null
                      }
                    }
                  ]
                },
                {
                  path: "stand",
                  name: "customer-deal-stand",
                  props: true,
                  components: { default: DealStand, dynamicHeaderTitle: CustomerHeaderTitle },
                  meta: {
                    breadcrumb: "deal stand"
                  }
                },
                {
                  path: "extra-orders",
                  name: "customer-deal-extra-orders",
                  props: true,
                  components: { default: DealExtraOrders, dynamicHeaderTitle: CustomerHeaderTitle },
                  meta: {
                    breadcrumb: "extra orders"
                  }
                },
                {
                  path: "history",
                  name: "customer-deal-history",
                  props: true,
                  components: { default: DealHistory, dynamicHeaderTitle: CustomerHeaderTitle },
                  meta: {
                    breadcrumb: "history"
                  }
                }
              ]
            }
          ]
        },
        {
          path: "activities",
          name: "customer-activities",
          meta: {
            breadcrumb: "customer activities"
          },
          redirect: { name: "customer-activities-table" },
          children: [
            {
              path: "",
              name: "customer-activities-table",
              props: true,
              components: { default: CustomerActivitiesTable, dynamicHeaderTitle: CustomerHeaderTitle }
            },
            {
              path: ":activityId",
              name: "customer-activity",
              props: true,
              components: { default: Activity, dynamicHeaderTitle: ActivityHeaderTitle },
              meta: {
                navigationTabsKey: null,
                breadcrumb: "activity"
              }
            }
          ]
        }
      ]
    }
  ]
}
