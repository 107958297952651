import { ApolloClient, InMemoryCache, from } from "@apollo/client/core"
import { BatchHttpLink } from "@apollo/client/link/batch-http"
import { errorLink } from "@/utils/errorInterceptor"

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    addTypename: true, // TODO: remove typename from httpLink on mutation operation
    SaleActivity: {
      fields: {
        dealContacts: {
          merge(existing, incoming) {
            return incoming
          }
        }
      }
    },
    Exhibitor: {
      fields: {
        comments: {
          merge(existing, incoming) {
            return incoming
          }
        }
      }
    },
    Deal: {
      fields: {
        exhibitor: {
          merge(existing, incoming) {
            return incoming
          }
        },
        event: {
          merge(existing, incoming) {
            return incoming
          }
        },
        user: {
          merge(existing, incoming) {
            return incoming
          }
        },
        tags: {
          merge(existing, incoming) {
            return incoming
          }
        }
      }
    },
    Query: {
      fields: {
        myTeams: {
          keyArgs: false,
          merge(existing, incoming) {
            return incoming
          }
        },
        teams: {
          keyArgs: false,
          merge(existing, incoming) {
            return incoming
          }
        },
        teamUsersByTeamId: {
          keyArgs: ["team_id"],
          merge(existing, incoming) {
            return incoming
          }
        },
        getAvailableUsersToAddToTeam: {
          merge(existing, incoming) {
            return incoming
          }
        },
        team: {
          merge(existing, incoming) {
            return incoming
          }
        },
        dealContactsByDealId: {
          merge(existing, incoming) {
            return incoming
          }
        },
        standsByDealId: {
          keyArgs: ["deal_id"],
          merge(existing, incoming) {
            return incoming
          }
        },
        saleActivitiesByUserId: {
          merge(existing, incoming) {
            return incoming
          }
        },
        saleActivitiesByTeamUser: {
          merge(existing, incoming) {
            return incoming
          }
        },
        saleActivitiesByTeamId: {
          merge(existing, incoming) {
            return incoming
          }
        },
        saleActivitiesByDealId: {
          merge(existing, incoming) {
            return incoming
          }
        },
        saleActivitiesByCustomerId: {
          merge(existing, incoming) {
            return incoming
          }
        },
        customers: {
          keyArgs: false,
          merge(existing, incoming) {
            return incoming
          }
        },
        customer: {
          merge(existing, incoming) {
            return incoming
          }
        },
        sales2Users: {
          merge(existing, incoming) {
            return incoming
          }
        },
        dealExtraOrdersByDealId: {
          keyArgs: ["deal_id"],
          merge(existing, incoming) {
            return incoming
          }
        },
        getDealExtraOrdersByTeamId: {
          keyArgs: ["team_id"],
          merge(existing, incoming) {
            return incoming
          }
        },
        dealContactsByExhibitorId: {
          keyArgs: ["exhibitor_id"],
          merge(existing, incoming) {
            return incoming
          }
        },
        signupExhibitorsByEventId: {
          keyArgs: ["event_id", "where"],
          merge(existing, incoming) {
            return incoming
          }
        },
        dealsByUserId: {
          merge(existing, incoming) {
            return incoming
          }
        },
        dealsByTeamUser: {
          merge(existing, incoming) {
            return incoming
          }
        },
        dealsByTeamId: {
          merge(existing, incoming) {
            return incoming
          }
        },
        dealsByCustomerId: {
          keyArgs: ["customer_id"],
          merge(existing, incoming) {
            return incoming
          }
        },
        invoicingStandsByTeamId: {
          keyArgs: ["team_id"],
          merge(existing, incoming) {
            return incoming
          }
        },
        invoicingStandByDealId: {
          keyArgs: ["id"],
          merge(existing, incoming) {
            return incoming
          }
        }
      }
    }
  }
})

function getHeaders() {
  const headers = {}
  const token = localStorage.getItem("access-token")
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  headers["Access-Control-Allow-Origin"] = "*"
  headers["Content-Type"] = "application/json"
  return headers
}

// HTTP connection to the API
const httpLink = new BatchHttpLink({
  uri: `${process.env.VUE_APP_API_URL}/graphql`,
  batchMax: 6, // No more than 6 operations per batch
  batchInterval: 30, // Wait no more than 30ms after first batched operation
  fetch: (uri, options) => {
    options.headers = getHeaders()
    return fetch(uri, options)
  }
})

const apolloClient = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "none"
    },
    query: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "none"
    },
    mutate: {
      errorPolicy: "none"
    }
  }
})

export default apolloClient
