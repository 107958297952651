<template>
  <div class="flex flex-col">
    <div class="contents">
      <div v-if="customerContact" class="flex-col max-h-96 overflow-y-auto">
        <div class="flex-col items-center">
          <div class="flex justify-between mt-2">
            <div class="flex-col w-1/2">
              <label class="text-gray-500">Email</label>
              <input
                v-model="customerContact.email"
                v-select-text
                type="email"
                class="input"
                :class="{ 'input--invalid': v$.email.$error }"
                data-test="email"
                @input="debouncedSearchByEmail(customerContact.email, index)"
                @blur="v$.email.$touch"
              />
              <p v-if="v$.email.$error" class="text-error" data-test="email-validation">
                {{ v$.email.$errors[0].$message }}
              </p>
            </div>

            <div class="flex-col ml-3 w-1/2">
              <label class="text-gray-500">Phone</label>
              <div class="flex flex-row">
                <select
                  v-model="customerContact.validatedPhoneNumber.countryCode"
                  class="input h-fit w-1/3"
                  @change="() => validateContactPhone(customerContact)"
                >
                  <option key="norway" value="+47">+47</option>
                  <option key="sweden" value="+46">+46</option>
                  <option disabled>─────</option>
                  <option v-for="countryCode in props.countryCodes" :key="countryCode" :value="countryCode">
                    {{ countryCode }}
                  </option>
                </select>
                <input
                  v-model="customerContact.validatedPhoneNumber.number"
                  v-select-text
                  class="input w-2/3 ml-2"
                  type="tel"
                  oninput="value = value.replace(/[^0-9+\s()-]/g, '')"
                  @focus="() => showUnformattedPhoneNumber(customerContact)"
                  @blur="() => validateContactPhone(customerContact)"
                  data-test="phone"
                />
              </div>
              <p
                v-if="customerContact.phone && !customerContact.validatedPhoneNumber.isValid"
                class="text-error"
                data-test="phone-validation"
              >
                Not a valid phonenumber
              </p>
            </div>
          </div>

          <div class="flex justify-between mt-2">
            <div class="flex-col w-1/2">
              <label class="text-gray-500">First Name</label>
              <input
                v-model="customerContact.firstname"
                v-select-text
                class="input"
                :class="{ 'input--invalid': v$.firstname.$error }"
                data-test="firstname"
                @blur="v$.firstname.$touch"
              />
              <p v-if="v$.firstname.$error" class="text-error" data-test="firstname-validation">
                {{ v$.firstname.$errors[0].$message }}
              </p>
            </div>

            <div class="flex-col ml-3 w-1/2">
              <label class="text-gray-500">Last Name</label>
              <input
                v-model="customerContact.lastname"
                v-select-text
                class="input"
                :class="{ 'input--invalid': v$.lastname.$error }"
                data-test="lastname"
                @blur="v$.lastname.$touch"
              />
              <p v-if="v$.lastname.$error" class="text-error" data-test="lastname-validation">
                {{ v$.lastname.$errors[0].$message }}
              </p>
            </div>
          </div>

          <div class="flex-col mt-2">
            <label class="text-gray-500">Title</label>
            <input v-model="customerContact.title" v-select-text class="input" data-test="title" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- export name for cache (<keep-alive />) -->
<script>
export default {
  name: "BaseCustomerContact"
}
</script>

<script setup>
import { computed, reactive, ref, watch, inject, onMounted } from "vue"
import { email, required, requiredIf } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { debounce, deepClone } from "@/utils/helpers"
import { useQuery } from "@vue/apollo-composable"
import DEAL_CONTACTS_BY_EMAIL from "@/constants/graphql/queries/deal-contacts-by-email.gql"
import { validateMobileNumber } from "@/utils/phoneNumberUtils"

const emit = defineEmits(["loading"])
const props = defineProps({
  contact: {
    type: Object,
    required: true
  },
  isSoldDeal: {
    type: Boolean,
    required: false,
    default: false
  },
  isNorwegianExhibitor: {
    type: Boolean,
    required: false,
    default: false
  },
  countryCodes: {
    type: Array,
    required: false,
    default: () => []
  }
})
const store = inject("store")
const evaluating = ref(false)
const customerContact = ref(props.contact) // props.contact is used by reference

onMounted(() => {
  v$.value.$touch()
})

const showUnformattedPhoneNumber = (contact) => {
  if (!contact.validatedPhoneNumber?.isValid && !contact.validatedPhoneNumber?.countryCode) {
    // not a valid phonenumber, so just show what we have stored in db
    contact.validatedPhoneNumber.number = contact.phone
  }
}

const getPhoneNumberWithoutSpaces = (contact) => {
  let number = contact.phone
  if (contact.validatedPhoneNumber) {
    number = (contact.validatedPhoneNumber.countryCode + contact.validatedPhoneNumber.number).replace(/\s/g, "")
  }
  return number
}

const validateContactPhone = async (contact) => {
  contact.phone = getPhoneNumberWithoutSpaces(contact)
  if (!contact.phone) {
    contact.validatedPhoneNumber.isValid = false
    return
  }

  evaluating.value = true
  const res = await validateMobileNumber(contact.phone)
  evaluating.value = false
  if (res) {
    contact.validatedPhoneNumber = res
  } else {
    contact.validatedPhoneNumber.isValid = false
  }
}

/* Handle searching for contact by email */
const variablesForQuery = reactive({
  email: "",
  index: null
})
const emailForQuery = computed(() => variablesForQuery.email)
const enabledDealContactsQuery = ref(false)

const {
  onResult,
  onError,
  loading: loadingContacts
} = useQuery(DEAL_CONTACTS_BY_EMAIL, { email: emailForQuery }, () => ({
  enabled: enabledDealContactsQuery.value,
  fetchPolicy: "no-cache"
}))
onResult(async ({ data: { dealContactsByEmail } }) => {
  enabledDealContactsQuery.value = false
  if (dealContactsByEmail?.length) {
    const foundContact = deepClone(dealContactsByEmail[0])
    foundContact.validatedPhoneNumber = {
      countryCode: "",
      number: foundContact.phone
    }

    store.ui.methods.setExceptionalLoading(true)
    await validateContactPhone(foundContact)
    store.ui.methods.setExceptionalLoading(false)
    customerContact.value = foundContact
  }
})
onError(() => {
  enabledDealContactsQuery.value = false
})

const debouncedSearchByEmail = debounce((email, index) => {
  v$.value.$touch()
  if (!v$.value.email.$error) {
    variablesForQuery.email = email
    variablesForQuery.index = index
    if (email) {
      enabledDealContactsQuery.value = true
    }
  }
}, 300)

/* Validation */
const rules = computed(() => ({
  firstname: { required },
  lastname: { required },
  email: { requiredIfRef: requiredIf(props.isSoldDeal), email }
}))

const v$ = useVuelidate(rules, customerContact)

watch(
  () => [loadingContacts.value, evaluating.value],
  () => {
    emit("loading", loadingContacts.value || evaluating.value)
  },
  { immediate: true }
)
</script>
